<template>
  <div class="orders__item" :aria-expanded="this.isOpened" >
    <header class="orders__item__header" @click="toggleOrder(order, order?.paymentJson?.line_items?.data)">
      <div class="orders__item__header__left">
        <h3 v-if="order.paymentJson.object === 'checkout.session'" class="orders__item__header__address">
          {{order?.metadata?.customerOrderId }}
        </h3>
        <h3 v-if="order.paymentJson.object === 'order'" class="orders__item__header__address">
          {{ order?.shipping_details?.address?.line1 }}
        </h3>
        <h1 class="orders__item__header__name">
          {{ (order?.metadata?.orderType==='selection-fee') ? 'Selection Fee' : order?.description }}
        </h1>
      </div>
      <div class="orders__item__header__right">
        <h3 class="orders__item__header__date">
          {{ dateFormatted }}
        </h3>
        <h2 class="orders__item__header__total">
          {{ formatCurrency(order?.amount_total / 100) }}
        </h2>
      </div>
    </header>
    <div class="orders__item__content">
      <ul class="orders__item__items" v-for="(item, index) in lineItems" :key="index">
        <li class="orders__item__items__item" :class="{ 'service-fee': item.orderType === 'selection-fee' }">
          <div class="orders__item__items__item__image">
            <div v-on:load="getFurnitureData">
            </div>
            <img
              v-if="order && order.line_items_image"
              :src="order.line_items_image[index]"
            >
            <img
              v-else-if="item.orderType === 'selection-fee'"
              src="@/assets/images/logomark-secondary.svg"
              class="orders__item__logomark 1"
            >
            <span v-if="order.line_items_images?.[index]?.url">
              <img :src="order.line_items_images?.[index]?.url"
              class="orders__item__logomark" >
            </span>
            <span v-else>
              <img src="@/assets/images/logo-new-green.png"
              class="orders__item__logomark" >
            </span>
          </div>
          <header class="orders__item__items__item__details">
            <h3
              v-if="order.line_items_shipping_data?.[index]"
              class="orders__item__items__item__details__brand" >
<!--              {{ item.furnitureData.vendor.name }}-->
              <span v-if="order.line_items_shipping_data?.[index]?.orderNumber">
                - {{ order.line_items_shipping_data?.[index]?.orderNumber }}
              </span>
            </h3>
            <h2 class="orders__item__items__item__details__product-name">
              {{ item.description }} <span>&times; {{ item.quantity }}</span>
            </h2>
            <div v-if="order.line_items_shipping_data?.[index]" class="orders__item__items__item__details__status">
              <div v-if="order.line_items_shipping_data?.[index]?.estDeliveryStart || order.line_items_shipping_data?.[index]?.estDeliveryEnd">
                Estimated Delivery: {{ formatEstimatedDelivery(order.line_items_shipping_data?.[index]) }}
              </div>
              <a :href="order.line_items_shipping_data?.[index]?.trackingUrl" target="_blank">Tracking Link &rarr;</a>
            </div>
          </header>
          <div class="orders__item__items__item__price">
            {{ formatCurrency(item.amount_total / 100) }}
          </div>
        </li>
      </ul>
      <footer class="orders__item__footer">
        <div class="orders__item__footer__order-id">Order Id: {{ order?.metadata?.customerOrderId }}</div>
        <div class="orders__item__footer__subtotal"></div>
        <div v-if="order?.paymentJson?.total_details" class="orders__item__footer__total">
          <table>
            <tr class="total__subtotal">
              <td>Subtotal</td>
              <td>{{ formatCurrency(order?.paymentJson?.amount_subtotal / 100) }}</td>
            </tr>
            <tr class="total__tax">
              <td>Taxes</td>
              <td>{{ formatCurrency(order?.paymentJson?.total_details?.amount_tax / 100) }}</td>
            </tr>
            <tr v-if="order?.metadata?.orderType !== 'selection-fee'" class="total__shipping">
              <td>Shipping</td>
              <div>{{ formatCurrency(order?.paymentJson?.total_details?.amount_shipping / 100) }}</div>
            </tr>
            <tr v-if="order?.paymentJson?.total_details?.amount_discount" class="total__discount">
              <td>Discounts</td>
              <td>-{{ formatCurrency(order?.paymentJson?.total_details?.amount_discount / 100) }}</td>
            </tr>
            <tr class="total__final">
              <td>Total</td>
              <td>{{ formatCurrency(order?.paymentJson?.amount_total / 100) }}</td>
            </tr>
          </table>
        </div>
        <div class="orders__item__footer__cta">
          <router-link to="/contact" class="button primary customGreenBTN">Contact Us About This Order &nbsp; <span class="icon-arrow-right"></span></router-link>
          <!-- <a :href="order.receiptUrl" class="button primary">Download Receipt</a> -->
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { getDoc, doc } from 'firebase/firestore'
export default defineComponent({
  name: 'Order',
  props: ['isOpen', 'order'],
  data () {
    return {
      isOpened: this.isOpen
    }
  },
  computed: {
    ...mapState(['firestore']),
    dateFormatted () {
      // 10 digit timestamp needs to be multiplied as Date expects 13 digits
      return new Date(this.order.created * 1000).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
    },
    lineItems () {
      return this.order?.line_items || []
    }
  },

  methods: {
    ...mapActions(['getLineItem']),
    toggleOrder (order, itemIds) {
      this.isOpened = !this.isOpened

      this.getFurnitureData(order, itemIds)
    },
    formatCurrency (number) {
      if (!number && number !== 0) {
        return '...'
      }

      const currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      })

      return currency.format(number)
    },
    formatEstimatedDelivery (shippingData) {
      if (!shippingData) return ''
      const start = shippingData.estDeliveryStart || ''
      const end = shippingData.estDeliveryEnd || ''
      const settings = {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }
      let startDate, endDate
      // For some reason...using '-' as a date separator causes the date to be a day off...
      if (start) startDate = new Date(start.replace(/-/g, '/')).toLocaleString('en-US', settings)
      if (end) endDate = new Date(end.replace(/-/g, '/')).toLocaleString('en-US', settings)
      return (startDate || '') + (endDate ? ' - ' + endDate : '')
    },
    async getFurnitureData (order, paymentJson) {
      var furnitureIds = []

      if (order?.metadata?.orderType === 'furniture') {
        if (order.paymentJson.object === 'checkout.session') {
          furnitureIds = JSON.parse(order.metadata.item_id)
        }
        // Then get the furniture data for each line item in the order
        for (const j in paymentJson) {
          const targetLineItem = paymentJson[j]
          var furniture = {}
          if (order.paymentJson.object === 'checkout.session') {
            furniture = await getDoc(doc(this.firestore, 'furniture', furnitureIds[j]))
            paymentJson[j].furnitureData = furniture.data()
            //
            const lineItemData = await this.getLineItem({ orderId: order.stripeOrderId, lineItemId: targetLineItem.id })

            if (lineItemData?.shipping) {
              order.line_items.shipping = lineItemData.shipping
            }
          } else {
            //
            furniture = await getDoc(doc(this.firestore, 'furniture', targetLineItem.product))
            //
            paymentJson[j].furnitureData = furniture.data()
            const lineItemData = await this.getLineItem({ orderId: order.stripeOrderId, lineItemId: targetLineItem.id })

            if (lineItemData?.shipping) {
              order.line_items.shipping = lineItemData.shipping
            }
          }
        }
        order.line_items.data = paymentJson
      }
    }
  }
})
</script>

<style lang="scss">
.orders__item {
  background: $tt-classic-white;
  margin-bottom: var(--gutter);
  color: $tt-walnut;

  &__content {
    @include breakpoint($s) {
      padding: 0 var(--gutter) var(--gutter);
    }
  }
  &__logomark {
    width: 120px;
    height: 120px;
    padding: 0 !important;
    background-color: transparent !important;
  }

  &[aria-expanded=true] {
    @include breakpoint($m-up) {
      padding: calc(var(--gutter) * 2);
      position: relative;
    }

    .orders__item__header {
      padding: 0;

      @include breakpoint($s) {
        padding: var(--gutter);
      }
    }
  }

  .orders__item__header {
    @include clearfix;
    @include h3;
    cursor: pointer;
    padding: calc(var(--gutter) * 2);

    @include breakpoint($s) {
      padding: var(--gutter);
    }

    .orders__item__header__left,
    .orders__item__header__right {
      float: left;
      width: 50%;
    }

    .orders__item__header__right {
      text-align: right;
    }

    &__address,
    &__date {
      color: $tt-green;
      margin-bottom: calc(var(--gutter) * 0.5);
    }
    &__name,
    &__total {
      font-family: Sohne, serif;
      font-size: 1.5em;
      text-transform: none;

      @include breakpoint($s) {
        font-size: 1.5em;
      }
    }
  }

  &[aria-expanded=false] .orders__item__header:hover {
    background-color: darken($brand-secondary, 12.5%);
  }

  &[aria-expanded=false] .orders__item__content {
    display: none;
  }

  .orders__item__items {
    margin-top: var(--gutter);

    @include breakpoint($s) {
      margin-top: 0;
    }

    &__item {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 0 var(--gutter);
      padding: var(--gutter) 0;

      &.service-fee {
        .orders__item__items__item {
          &__image {
            grid-column: span 1;
          }
          &__price {
            grid-column: span 3;
          }
        }
      }

      &__image {
        position: relative;
        grid-column: span 2;
        line-height: 0;
        height: 100%;

        img {
          @include center-in-parent;
          padding: calc(var(--gutter) * 0.5);
          background-color: white;
          border-radius: .333rem;
          width: auto;
          max-height: 100%;
        }

        @include breakpoint($s) {
          grid-column: span 3;

          img {
            padding: 0;
          }
        }
      }

      &__details {
        padding: var(--gutter);
        grid-column: span 8;

        @include breakpoint($s) {
          grid-column: span 6;
          padding: var(--gutter) 0;
        }

        &__brand {
          @include h3;
        }

        &__product-name {
          margin-bottom: calc(var(--leading) / 2);
          text-transform: none;

          @include breakpoint($s) {
            font-size: 0.75em;
          }
        }

        &__status {
          @include meta-heading;
        }
      }

      &__price {
        @include h3;
        font-size: 1em;
        grid-column: span 2;
        text-align: right;
        padding: var(--gutter) 0;

        @include breakpoint($m-up) {
          font-size: 1.5em;
        }
        @include breakpoint($s) {
          grid-column: span 3;
          font-size: 0.75em;
        }
      }
    }
  }

  .orders__item__footer {
    @include meta-text;
    padding-top: var(--gutter);

    &__order-id {
      text-align: right;
      padding-bottom: var(--gutter);
    }

    &__total {
      text-align: right;
      margin-bottom: var(--leading);

      table {
        margin-left: auto;
        td:nth-child(1) {
          padding-right: calc(var(--gutter) * 2);
        }
      }
    }

    &__cta {
      text-align: right;
    }
  }
}
</style>
